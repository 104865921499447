import "jquery"
import axios from "axios"
import $ from 'jquery';

var client_id=window.current_client_id
var user_id=window.current_client_id

export const dashboard_app={
    data() {
        return {
            client_id:client_id,
            user_id:user_id,
            sms_code_sent:{status:"nosend",msg:null},
            register:{status:"nosend",msg:null},
            sms_code:null,
            spinners:{send_sms_code:false,register_number:false}
        }
      },  
      methods: {
        send_sms_code:function(){
            this.spinners.send_sms_code=true
            axios.get(`clients/${this.client_id}/ask_verify_code`).then(res=>{
                this.spinners.send_sms_code=false
                this.sms_code_sent.status=true
            }).catch(err=>{
                console.log("errr",err);  
                this.sms_code_sent={status:"error_sent",msg:err.response.msg}
            })
        },
        register_number:function(){
            this.spinners.register_number=true
            axios.get(`clients/${this.client_id}/register_number`).then(res=>{
                this.spinners.send_sms_code=false
                this.register.status=true
                location.reload(); 
            }).catch(err=>{
                console.log("errr",err);  
                this.register={status:false,msg:err.response.msg}
            })
        },
        verify_code:function(){


        },
        openmodal:function(modal_id){
            $("#"+modal_id).modal("show")
        },
        closemodal:function(modal_id){
            $("#"+modal_id).modal("hide")
        }
    }
}